<template>
  <a-layout>
    <a-layout-sider
      width="320px"
      :style="{
        overflow: 'auto',
        height: '100vh',
        left: 0,
        background: '#fff',
        'box-shadow': '-2px 0 10px grey',
      }"
    >
      <data-sider />
    </a-layout-sider>
    <a-layout>
      <a-layout-header :style="{ background: '#fff', padding: 0 }">
        <region-search-view :style="{ margin: '0 0 0 24px' }" />
      </a-layout-header>
      <a-layout-content
        :style="{
          margin: '24px 16px 0 16px',
          background: '#fff',
        }"
      >
        <map-view />
      </a-layout-content>
      <a-layout-footer style="text-align: right">
        <h4>问题反馈 <a> zhangyufei49@163.com</a></h4>
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import DataSider from "./components/DataSider.vue";
import MapView from "./components/MapView.vue";
import RegionSearchView from "./components/RegionSearchView.vue";

export default {
  name: "App",
  components: {
    DataSider,
    MapView,
    RegionSearchView,
  },
};
</script>

<style>
#app {
  min-width: 800px;
}
</style>
