<template>
  <div id="echart-view" style="height: 100%; width: 100%"></div>
</template>

<script>
let echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/map");
require("echarts/lib/component/title");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/visualMap");
require("echarts/lib/component/toolbox");
import region from "../region.js";

export default {
  name: "MapView",
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    // 获取geo数据
    this.$watch(
      () => this.$sharedState.region,
      (newRegion) => {
        if (this.chart === null) {
          const view = document.getElementById("echart-view");
          this.chart = echarts.init(view);
        }
        this.chart.showLoading();
        region.getGeoData(newRegion.key, (data) => {
          this.chart.hideLoading();
          echarts.registerMap(String(newRegion.key), data);
          this.$sharedState.geoData = data;
        });
      }
    );
    this.$watch(
      () => this.$sharedState.chartOpts,
      (opts) => {
        this.chart.setOption(opts);
      },
      { deep: true }
    );
    window.addEventListener("resize", () => {
      if (this.chart !== null) {
        this.chart.resize();
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
