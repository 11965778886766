import { createApp, reactive } from 'vue'
import App from './App.vue'
import {
    Button, Input, Select, Space, Switch,
    Layout, Menu, message, InputNumber,
    Divider, List, Row, Col
} from 'ant-design-vue'

const { Option } = Select

const app = createApp(App)
app.config.productionTip = false

// 需要注册的模块
const antdEles = [
    Button, Layout, Menu, Input, Select,
    Switch, Space, InputNumber, Divider,
    List, Row, Col, Option
]
antdEles.forEach((x) => {
    app.use(x)
})
app.config.globalProperties.$message = message
app.config.globalProperties.$sharedState = reactive({
    region: {},
    geoData: null,
    chartOpts: {},
    color: parseInt(localStorage.color || 0)
})

app.mount('#app')
