<template>
  <div style="margin: 20px 20px 0 20px" v-if="inited">
    <a-input
      v-model:value="$sharedState.chartOpts.title.text"
      placeholder="主标题"
      class="sider-item"
      allow-clear
    />
    <a-input
      v-model:value="$sharedState.chartOpts.title.subtext"
      placeholder="副标题"
      class="sider-item"
      allow-clear
    />
    <div class="sider-item">
      <a-space>
        显示地域名
        <a-switch
          v-model:checked="$sharedState.chartOpts.series[0].label.show"
        />
      </a-space>
    </div>
    <div class="sider-item">
      <a-space>
        数值范围
        <a-input-number
          v-model:value="$sharedState.chartOpts.visualMap.min"
        ></a-input-number>
        -
        <a-input-number
          v-model:value="$sharedState.chartOpts.visualMap.max"
        ></a-input-number>
      </a-space>
    </div>
    <a-divider />
    <a-list item-layout="vertical" :data-source="listData" :split="false">
      <template #renderItem="{ item }">
        <a-list-item>
          <a-space>
            <span style="margin-left: 10px">{{ item.name }}</span>
            <a-input-number style="width: 150px" v-model:value="item.value" />
          </a-space>
        </a-list-item>
      </template>
    </a-list>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import colors from "../color.js";

export default defineComponent({
  data() {
    return {
      inited: false,
      listData: [],
    };
  },
  watch: {},
  mounted() {
    this.$watch(
      () => this.$sharedState.geoData,
      (geoData) => {
        // 初始化 echarts 用的 options
        const sdata = [];
        const features = geoData.features;
        for (let i = 0; i < features.length; i++) {
          const ele = features[i];
          sdata.push({
            name: ele.properties.name,
            value: parseInt((100 / features.length) * i),
          });
        }
        this.listData = sdata;
        this.$sharedState.chartOpts = {
          title: {
            text: "这是主要标题可以为空",
            subtext: "这是副标题可以为空",
          },
          tooltip: {
            trigger: "item",
            formatter: "{b}  {c}",
          },
          toolbox: {
            show: true,
            orient: "horizontal",
            left: "right",
            top: "top",
            feature: {
              saveAsImage: {},
            },
          },
          visualMap: {
            min: 0,
            max: 100,
            text: ["High", "Low"],
            realtime: true,
            calculable: true,
            inRange: {
              color: colors[this.$sharedState.color],
            },
          },
          series: [
            {
              name: "数据",
              type: "map",
              map: String(this.$sharedState.region.key),
              label: {
                show: false,
              },
              data: sdata,
            },
          ],
        };
        this.inited = true;
      }
    );
    this.$watch(
      () => this.$sharedState.color,
      (color) => {
        this.$sharedState.chartOpts.visualMap.inRange.color = colors[color];
      }
    );
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sider-item {
  margin-top: 20px;
  width: 100%;
}
</style>
