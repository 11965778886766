const colors = [
    ['rgb(96,226,185)', 'rgb(55,168,223)', 'rgb(150,157,226)', 'rgb(87,97,134)'],
    ['rgb(209,161,119)', 'rgb(134,148,128)', 'rgb(211,113,113)', 'rgb(99,101,105)'],
    ['rgb(234,165,213)', 'rgb(136,174,223)', 'rgb(79,188,226)', 'rgb(71,96,134)'],
    ['rgb(246,242,137)', 'rgb(204,243,156)', 'rgb(103,197,255)', 'rgb(252,140,166)'],
    ['rgb(41,192,194)', 'rgb(255,176,117)', 'rgb(80,168,236)', 'rgb(173,152,217)'],
    ['rgb(226,173,0)', 'rgb(0,141,212)', 'rgb(45,145,158)', 'rgb(185,41,46)'],
    ['rgb(232,214,154)', 'rgb(255,169,63)', 'rgb(212,78,70)', 'rgb(126,46,63)'],
    ['rgb(96,336,185)', 'rgb(55,168,223)', 'rgb(150,157,226)', 'rgb(87,97,134)'],
    ['rgb(252,199,17)', 'rgb(229,113,33)', 'rgb(185,33,39)', 'rgb(35,103,112)'],
    ['rgb(134,205,229)', 'rgb(220,141,192)', 'rgb(114,128,177)', 'rgb(102,91,148)']
]

export default colors