<template>
  <a-row type="flex" justify="space-between">
    <a-col flex="300px">
      <a-select
        show-search
        filter-option
        autofocus
        :default-active-first-option="false"
        v-model:value="value"
        placeholder="输入要查看的 '省/市' 名"
        style="width: 100%"
        :not-found-content="null"
        :options="options"
        @select="onSelect"
      />
    </a-col>
    <a-col>
      <a-select v-model:defaultValue="$sharedState.color" :onChange="onColor">
        <a-select-option v-for="(_, index) in colors" :key="index">{{
          "主题" + index
        }}</a-select-option>
      </a-select>
    </a-col>
    <a-col :span="8">
      <span style="float: right; margin-right: 4px">
        地图占比数据可视化工具--<b style="font-size: 20px">大饼</b>
        <img src="../assets/logo.png" />
      </span>
    </a-col>
  </a-row>
</template>

<script>
import { defineComponent, ref } from "vue";
import region from "../region.js";
import colors from "../color.js";

export default defineComponent({
  data() {
    return {
      value: ref(),
      options: [],
      colors: colors,
    };
  },
  methods: {
    onSelect(_, option) {
      if (option.key === this.$sharedState.region.key) return;
      this.$sharedState.region = option;
    },
    onColor(value) {
      const color = parseInt(value);
      this.$sharedState.color = color;
      localStorage.color = color;
    },
  },
  mounted() {
    region.getIndexData((val) => {
      // 第一次遍历，构造id到名字的表
      const id2name = {};
      for (const i of val) {
        id2name[i[0]] = i[1];
      }
      // 第 2 次遍历，生成 options
      var defopt = null;
      for (const i of val) {
        var name = i[1];
        if (i[0] % 10000 > 0) {
          name = id2name[parseInt(i[0] / 10000) * 10000] + " | " + i[1];
        }
        const opt = { value: name, key: i[0] };
        this.options.push(opt);
        if (i[0] === 445400) {
          defopt = opt;
        }
      }
      // 设定默认的使用大饼的地图
      this.value = "大饼市";
      this.onSelect(this.value, defopt);
    });
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
