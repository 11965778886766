import { Modal } from "ant-design-vue";
import { createVNode } from "vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

function getRegionData(name, retryTips, callback) {
    fetch("region/" + name + ".json")
        .then((resp) => {
            if (resp.status !== 200) {
                // 不停重试
                getRegionData(name, retryTips, callback)
                return;
            }
            resp.json().then(callback);
        })
        .catch((reson) => {
            // 显示重试信息
            Modal.confirm({
                title: retryTips,
                icon: createVNode(ExclamationCircleOutlined),
                okText: "重试",
                content: reson,
                cancelButtonProps: {
                    disabled: true,
                },
                onOk() {
                    getRegionData(name, retryTips, callback)
                },
            });
        });
}

export default {
    getIndexData(callback) {
        return getRegionData('index', '获取一些信息失败了，请重试', callback)
    },
    getGeoData(id, callback) {
        return getRegionData(id, '获取地图数据失败，请重试', callback)
    }
}